import React, { useState } from 'react';

function InputForm({

  inputTokens,

  outputTokens,

  multiplier,

  customModel,

  customInputCost,

  customOutputCost,

  onInputTokensChange,

  onOutputTokensChange,

  onMultiplierChange,

  onCustomModelChange,

  onCustomInputCostChange,

  onCustomOutputCostChange,

  onCalculate

}) {

  const [showCustomModel, setShowCustomModel] = useState(false);

  return (

    <div className="input-form">

      <div className="form-group">

        <label htmlFor="inputTokens">Eingabe-Tokens:</label>

        <input

          type="number"

          id="inputTokens"

          value={inputTokens}

          onChange={(e) => onInputTokensChange(parseInt(e.target.value) || 0)}

          min="0"

        />

      </div>

      <div className="form-group">

        <label htmlFor="outputTokens">Ausgabe-Tokens:</label>

        <input

          type="number"

          id="outputTokens"

          value={outputTokens}

          onChange={(e) => onOutputTokensChange(parseInt(e.target.value) || 0)}

          min="0"

        />

      </div>

      <div className="form-group">

        <label htmlFor="multiplier">Multiplikator:</label>

        <input

          type="number"

          id="multiplier"

          value={multiplier}

          onChange={(e) => onMultiplierChange(parseInt(e.target.value) || 1)}

          min="1"

        />

      </div>

      <div className="custom-model-section">

        <div className="custom-model-header" onClick={() => setShowCustomModel(!showCustomModel)}>

          <h3>Benutzerdefiniertes Modell</h3>

          <span className={`toggle-icon ${showCustomModel ? 'open' : ''}`}>▼</span>

        </div>

        {showCustomModel && (

          <div className="custom-model-content">

            <div className="form-group">

              <label htmlFor="customModel">Modell-Name:</label>

              <input

                type="text"

                id="customModel"

                value={customModel}

                onChange={(e) => onCustomModelChange(e.target.value)}

              />

            </div>

            <div className="form-group">

              <label htmlFor="customInputCost">Eingabekosten pro Million Token:</label>

              <input

                type="number"

                id="customInputCost"

                value={customInputCost}

                onChange={(e) => onCustomInputCostChange(e.target.value)}

                step="0.01"

                min="0"

              />

            </div>

            <div className="form-group">

              <label htmlFor="customOutputCost">Ausgabekosten pro Million Token:</label>

              <input

                type="number"

                id="customOutputCost"

                value={customOutputCost}

                onChange={(e) => onCustomOutputCostChange(e.target.value)}

                step="0.01"

                min="0"

              />

            </div>

          </div>

        )}

      </div>

    </div>

  );

}

export default InputForm; 

import React, { useState } from 'react';
import { sendContactForm } from '../services/contactService';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState({
    message: '',
    isError: false
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ message: '', isError: false });
    
    try {
      await sendContactForm(formData);
      setStatus({ 
        message: 'Ihre Nachricht wurde erfolgreich gesendet!', 
        isError: false 
      });
      // Formular zurücksetzen
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setStatus({ 
        message: error.message, 
        isError: true 
      });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="contact">
      <h1>Kontakt</h1>
      {status.message && (
        <div className={`status-message ${status.isError ? 'error' : 'success'}`}>
          {status.message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-Mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Nachricht:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Nachricht senden</button>
      </form>
    </div>
  );
}

export default Contact; 
const sendContactForm = async (formData) => {
  try {
    const response = await fetch('https://kirechner.ch/api/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      throw new Error('Fehler beim Senden der Nachricht');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Fehler beim Senden: ' + error.message);
  }
};

export { sendContactForm }; 
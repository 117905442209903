import React from 'react';
import { NavLink, Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="navigation">
      <NavLink to="/" end>Rechner</NavLink>
      <NavLink to="/instructions">Anleitung</NavLink>
      <NavLink to="/faq">FAQ</NavLink> 
    </nav>
  );
};

export default Navigation; 
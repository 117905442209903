import React, { useState, useEffect } from 'react';
import { isDataReady, hasValidPriceData, onDataLoaded, calculateCosts } from '../services/priceData';

function CostTable({ costs, currency, inputTokens, outputTokens, multiplier, customModel, customInputCost, customOutputCost }) {
  const [isLoading, setIsLoading] = useState(!isDataReady());
  const [hasError, setHasError] = useState(false);
  const [localCosts, setLocalCosts] = useState([]);

  // Funktion für die Berechnung
  const performCalculation = () => {
    // Stelle sicher, dass wir die Standardwerte aus den Input-Feldern verwenden
    const initialInputTokens = inputTokens || 1000;  // Standardwert 1000
    const initialOutputTokens = outputTokens || 1500;  // Standardwert 1500
    const initialMultiplier = multiplier || 1;  // Standardwert 1

    console.log('Berechne mit initialen Werten:', {
      initialInputTokens,
      initialOutputTokens,
      initialMultiplier
    });

    const newCosts = calculateCosts(
      initialInputTokens,
      initialOutputTokens,
      initialMultiplier,
      currency,
      customModel,
      customInputCost,
      customOutputCost
    );
    setLocalCosts(newCosts);
  };

  useEffect(() => {
    let isMounted = true;

    const handleDataLoaded = () => {
      if (isMounted) {
        console.log('CostTable: Daten wurden geladen');
        setIsLoading(false);
        setHasError(!hasValidPriceData());
        performCalculation();
      }
    };

    if (!isDataReady()) {
      console.log('CostTable: Registriere Callback für Datenladung');
      onDataLoaded(handleDataLoaded);
    } else {
      console.log('CostTable: Daten bereits geladen');
      handleDataLoaded();
    }

    return () => {
      isMounted = false;
    };
  }, [inputTokens, outputTokens, multiplier, currency, customModel, customInputCost, customOutputCost]);

  // Aktualisiere localCosts wenn sich die übergebenen costs ändern
  useEffect(() => {
    if (costs?.length > 0) {
      setLocalCosts(costs);
    }
  }, [costs]);

  if (isLoading) {
    return <div className="loading">Lade Preisdaten...</div>;
  }

  if (hasError) {
    return <div className="error">Fehler beim Laden der Preisdaten</div>;
  }

  if (!localCosts?.length) {
    return null;
  }

  const formatNumber = (num) => {
    return new Intl.NumberFormat('de-CH', {
      maximumFractionDigits: 4
    }).format(num);
  };

  const getProviderClass = (provider) => {
    return `provider-${provider.toLowerCase()}`;
  };

  return (
    <table className="cost-table">
      <thead>
        <tr>
          <th>Anbieter</th>
          <th>Modell</th>
          <th>Input Kosten pro Million</th>
          <th>Output Kosten pro Million</th>
          <th>Gesamtkosten</th>
        </tr>
      </thead>
      <tbody>
        {localCosts.map((cost, index) => (
          <tr key={index}>
            <td className={getProviderClass(cost.provider)}>
              <a href={cost.providerUrl} target="_blank" rel="noopener noreferrer">
                {cost.provider}
              </a>
            </td>
            <td>{cost.model}</td>
            <td>{formatNumber(cost.inputCostPerMillion)} {currency}</td>
            <td>{formatNumber(cost.outputCostPerMillion)} {currency}</td>
            <td>{formatNumber(cost.totalCost)} {currency}</td>
          </tr>
        ))}
      </tbody>
    </table>
    

  );
}

export default CostTable; 
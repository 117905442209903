import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Calculator from './components/Calculator';
import Instructions from './components/Instructions';
import FAQ from './components/FAQ';
import Contact from './components/Contact';
import Legal from './components/Legal';
import Privacy from './components/Privacy';
import Footer from './components/Footer';
import './styles.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Navigation />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Calculator />} />
            <Route path="/instructions" element={<Instructions />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/impressum" element={<Legal />} />
            <Route path="/datenschutz" element={<Privacy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App; 
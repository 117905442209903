import React, { useState, useEffect } from 'react';







import InputForm from './InputForm';







import CurrencySelector from './CurrencySelector';







import CostTable from './CostTable';







import { calculateCosts } from '../services/priceData';















function Calculator() {







  const [inputTokens, setInputTokens] = useState(1000);







  const [outputTokens, setOutputTokens] = useState(1500);







  const [multiplier, setMultiplier] = useState(1);







  const [selectedCurrency, setSelectedCurrency] = useState('CHF');







  const [costs, setCosts] = useState([]);







  const [customModel, setCustomModel] = useState('Anderes Model');







  const [customInputCost, setCustomInputCost] = useState('');







  const [customOutputCost, setCustomOutputCost] = useState('');















  const calculateCurrentCosts = () => {







    const results = calculateCosts(







      inputTokens,







      outputTokens,







      multiplier,







      selectedCurrency,







      customModel,







      customInputCost,







      customOutputCost







    );







    setCosts(results);







  };















  useEffect(() => {







    calculateCurrentCosts();







  }, [inputTokens, outputTokens, multiplier, selectedCurrency, customModel, customInputCost, customOutputCost]);















  return (







    <div className="calculator">







      <h1>KI Token Kostenrechner</h1>







      <CurrencySelector 







        selectedCurrency={selectedCurrency} 







        onCurrencyChange={setSelectedCurrency} 







      />







      <InputForm







        inputTokens={inputTokens}







        outputTokens={outputTokens}







        multiplier={multiplier}







        customModel={customModel}







        customInputCost={customInputCost}







        customOutputCost={customOutputCost}







        onInputTokensChange={setInputTokens}







        onOutputTokensChange={setOutputTokens}







        onMultiplierChange={setMultiplier}







        onCustomModelChange={setCustomModel}







        onCustomInputCostChange={setCustomInputCost}







        onCustomOutputCostChange={setCustomOutputCost}







      />







      <CostTable costs={costs} currency={selectedCurrency} />







      <p><strong>Hinweis:</strong> Es kann eventuell zu Diskrepanzen bei Preisänderungen der Anbieter kommen.</p>







    </div>







  );







}















export default Calculator; 







import React from 'react';

function CurrencySelector({ selectedCurrency, onCurrencyChange }) {
  const currencies = ['CHF', 'USD', 'EUR', 'GBP'];

  return (
    <div className="currency-selector">
      <label htmlFor="currency">Währung:</label>
      <select
        id="currency"
        value={selectedCurrency}
        onChange={(e) => onCurrencyChange(e.target.value)}
      >
        {currencies.map(currency => (
          <option key={currency} value={currency}>
            {currency}
          </option>
        ))}
      </select>
    </div>
  );
}

export default CurrencySelector; 
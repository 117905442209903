import React from 'react';

function FAQ() {
  return (
     <div>
         <h2>Häufig gestellte Fragen</h2>
         
         <h3>Wie genau sind die Kostenberechnungen?</h3>
         <p>Die Berechnungen basieren auf den offiziellen Preisen der API-Anbieter und werden regelmäßig aktualisiert. Die Preise können je nach Anbieter und Verwendungszweck variieren. Bitte beachten Sie, dass es bei Preisänderungen der Anbieter zu kurzen Verzögerungen kommen kann, bis unsere Systeme die neuen Preise übernommen haben.</p>
         
         <h3>Welche Währungen werden unterstützt?</h3>
         <p>Wir unterstützen CHF, USD, EUR und GBP. Die Preise werden automatisch in die gewählte Währung umgerechnet, basierend auf den aktuellen Wechselkursen.</p>

         <h3>Wie wird der Multiplikator verwendet?</h3>
         <p>Der Multiplikator ermöglicht es Ihnen, die Kosten für mehrere Durchläufe oder Anfragen zu berechnen. Beispiel: Wenn eine Anfrage 100 Tokens verwendet und der Multiplikator auf 5 gesetzt wird, werden die Kosten für 500 Tokens berechnet.</p>

         <h3>Was sind Tokens, und wie viele Tokens verbraucht eine Anfrage?</h3>
         <p>Tokens sind die kleinste Einheit bei der Textverarbeitung. Sie umfassen Wörter und auch Teile von Wörtern, insbesondere bei kürzeren Wörtern oder Präfixen/Suffixen.</p>

         <h3>Wie viele Tokens werden für verschiedene Textarten benötigt?</h3>
         <p>Hier sind einige typische Beispiele, wie viele Tokens verschiedene Textarten verbrauchen:</p>
         <ul>
             <li><strong>Kurze E-Mail (ca. 100 Wörter):</strong> etwa 150 Tokens</li>
             <li><strong>Seite im Taschenbuch (ca. 300 Wörter):</strong> etwa 400 Tokens</li>
             <li><strong>Eine A4-Seite voller Text (ca. 500 Wörter):</strong> etwa 700 Tokens</li>
             <li><strong>Ein Kapitel in einem Buch (ca. 3.000 Wörter):</strong> etwa 4.000 Tokens</li>
             <li><strong>Ein komplettes Buch (ca. 50.000 Wörter):</strong> etwa 70.000 Tokens</li>
         </ul>
         <p>Die tatsächliche Anzahl der Tokens kann je nach Text variieren, abhängig von der Komplexität und Wortlänge.</p>
         
         <h3>Wie berechne ich die ungefähren Kosten einer Anfrage?</h3>
         <p>Wenn die API-Preise beispielsweise 0.001 USD pro 1.000 Tokens betragen und Ihre Anfrage 500 Tokens umfasst, würde die Berechnung wie folgt aussehen:</p>
         <ul>
             <li>500 Tokens / 1.000 Tokens = 0.5</li>
             <li>0.5 * 0.001 USD = 0.0005 USD</li>
         </ul>
         <p>Die Gesamtkosten dieser Anfrage betragen daher 0.0005 USD.</p>
         
     </div>
  );
}



export default FAQ; 
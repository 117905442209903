// Konfiguration aus .env Datei
const API_BASE_URL =   'https://kirechner.ch'; 

// State für die geladenen Daten
let modelPrices = {};
let exchangeRates = {};
let isDataLoaded = false;
let dataLoadedCallbacks = [];

// Funktion zum Laden der Preisdaten vom Server
async function loadPriceData() { 
    console.log('Starte Laden der Preisdaten...');
    try { 
        const response = await fetch(`${API_BASE_URL}/api/getPriceData.php`);
        const data = await response.json(); 
        console.log('Geladene Preisdaten:', data);
        return data;
    } catch (error) {
        console.error('Error loading price data:', error);
        return null;
    }
}

// Verbesserte onDataLoaded Funktion
export function onDataLoaded(callback) {
    if (typeof callback !== 'function') {
        console.error('onDataLoaded: Callback muss eine Funktion sein');
        return;
    }
    
    if (isDataLoaded) {
        // Führe den Callback im nächsten Event-Loop-Zyklus aus
        setTimeout(callback, 0);
    } else {
        dataLoadedCallbacks.push(callback);
    }
}

// Initialisiere die Daten mit verbesserter Fehlerbehandlung
async function initializePriceData() {
    console.log('Starte Initialisierung...');
    try {
        const data = await loadPriceData();
        if (data) {
            console.log('Setze Preisdaten:', data);
            modelPrices = data.modelPrices;
            exchangeRates = data.exchangeRates;
        } else {
            console.warn('Keine Preisdaten empfangen, setze Standardwerte');
            modelPrices = {};
            exchangeRates = { 'CHF': 1.13, 'EUR': 1.08, 'USD': 1 };
        }
    } catch (error) {
        console.error('Fehler beim Laden der Preisdaten:', error);
        modelPrices = {};
        exchangeRates = { 'CHF': 1.13, 'EUR': 1.08, 'USD': 1 };
    } finally {
        isDataLoaded = true;
        console.log('Daten geladen, führe Callbacks aus. Anzahl Callbacks:', dataLoadedCallbacks.length);
        
        // Führe Callbacks sofort aus
        const callbacks = [...dataLoadedCallbacks];
        dataLoadedCallbacks = [];
        callbacks.forEach(callback => {
            try {
                callback();
            } catch (error) {
                console.error('Fehler beim Ausführen des Callbacks:', error);
            }
        });
    }
}

// Starte die Initialisierung
initializePriceData();

// Füge eine neue Funktion hinzu, um den Datenstatus zu prüfen
export function hasValidPriceData() {
    return isDataLoaded && Object.keys(modelPrices).length > 0;
}

// Aktualisiere die calculateCosts Funktion
export function calculateCosts(
    inputTokens = 0,  // Standardwert 0
    outputTokens = 0, // Standardwert 0
    multiplier = 1,   // Standardwert 1
    currency = 'CHF', // Standardwert CHF
    customModel = '',
    customInputCost = null,
    customOutputCost = null
) {
    console.log('calculateCosts aufgerufen:', {
        isDataLoaded,
        modelPricesLength: Object.keys(modelPrices).length,
        currency,
        inputTokens,
        outputTokens,
        multiplier
    });

    // Konvertiere Eingabewerte zu Zahlen und setze Standardwerte falls nötig
    inputTokens = Number(inputTokens) || 0;
    outputTokens = Number(outputTokens) || 0;
    multiplier = Number(multiplier) || 1;

    if (!isDataLoaded) {
        console.log('Warte auf Preisdaten...');
        return [];
    }

    if (!hasValidPriceData() && !customInputCost && !customOutputCost) {
        console.error('Keine gültigen Preisdaten verfügbar');
        return [];
    }

    const results = [];
    const exchangeRate = exchangeRates[currency] || 1; // Standardwert 1 falls keine Rate verfügbar

    // Add custom model calculation if all custom fields are filled
    if (customInputCost && customOutputCost) {
        const inputCost = (inputTokens / 1000000) * (parseFloat(customInputCost) || 0) * exchangeRate;
        const outputCost = (outputTokens / 1000000) * (parseFloat(customOutputCost) || 0) * exchangeRate;
        const totalCost = (inputCost + outputCost) * multiplier;
        
        results.push({
            provider: 'Custom',
            model: customModel || 'Custom Model',
            inputCost,
            outputCost,
            totalCost,
            inputCostPerMillion: parseFloat(customInputCost) || 0,
            outputCostPerMillion: parseFloat(customOutputCost) || 0
        });
    }

    // Calculate costs for predefined models
    Object.values(modelPrices).forEach(modelData => {
        const inputCost = (inputTokens / 1000000) * (modelData.inputPrice || 0) * exchangeRate;
        const outputCost = (outputTokens / 1000000) * (modelData.outputPrice || 0) * exchangeRate;
        const totalCost = (inputCost + outputCost) * multiplier;

        results.push({
            provider: modelData.provider || 'Unknown',
            providerUrl: modelData.providerUrl,
            model: modelData.model || 'Unknown Model',
            inputCost,
            outputCost,
            totalCost,
            inputCostPerMillion: modelData.inputPrice || 0,
            outputCostPerMillion: modelData.outputPrice || 0
        });
    });

    return results.sort((a, b) => a.totalCost - b.totalCost);
} 

// Optional: Exportiere eine Funktion zum Prüfen des Ladestatus
export function isDataReady() {
    return isDataLoaded;
}



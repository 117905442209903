import React from 'react';

function Instructions() {
  return (
    <div className="instructions">
      <h1>Anleitung</h1>
      <section>
        <h2>Wie benutze ich den KI-API Kostenrechner?</h2>
        <ol>
          <li>Geben Sie die Anzahl der Eingabe-Tokens ein</li>
          <li>Geben Sie die Anzahl der Ausgabe-Tokens ein</li>
          <li>Optional: Passen Sie den Multiplikator an</li>
          <li>Optional: Erstellen Sie ein eigenes Modell mit benutzerdefinierten Kosten</li>
          <li>Wählen Sie Ihre bevorzugte Währung</li>
          <li>Die Berechnung erfolgt automatisch bei jeder Änderung Ihrer Eingaben.</li>  
        </ol>
      </section>
      <section>
        <h2>Was sind Tokens?</h2>
        <p>Tokens sind die Grundeinheit für die Verarbeitung von Text in KI-Modellen. 
           Ein Token entspricht ungefähr 4 Zeichen oder 3/4 eines Wortes.</p>
      </section>
      <section className="space-y-2">
        <h2 className="text-xl font-semibold">Benutzerdefinierte Modelle</h2>
        <p className="text-gray-700">
          Sie können eigene Modelle mit spezifischen Kosten definieren:
        </p>
        <ul className="list-disc ml-6">
          <li>Input-Kosten pro 1 Mio Tokens eingeben</li>
          <li>Output-Kosten pro 1 Mio Tokens eingeben</li>
          <li>Name für das Modell festlegen (optional)</li>
        </ul>
      </section>
    </div>
  );
}

export default Instructions; 
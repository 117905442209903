import React from 'react';

function Legal() {
  return (
    <div className="legal">
      <section className="imprint">
        <h1>Impressum</h1>
        <div className="content"> 
          <p>SROMLINE GmbH</p>
          <p>Industriestrasse 4</p>
          <p>5432 Neuenhof</p>
          <p>Schweiz</p>
          
          <h2>Kontakt</h2>
          <p>Telefon: +41 56 534 17 53</p>
          <p>E-Mail: contact@sromline.com</p>
        </div>
      </section>
 
    </div>
  );
}

export default Legal; 